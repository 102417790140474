import React from 'react'
import { navigate } from 'gatsby'

const SearchForm = ({ query }) => (
    <form role="search" method="GET" >
        {/* <label htmlFor="searchFormInput">Search ...</label> */}
        <input
            aria-label="Search box"
            autoComplete="off"
            // autoFocus={true}
            id="searchFormInput"
            name="q"
			onChange={e => navigate(`/?q=${encodeURIComponent(e.target.value)}`)}
			placeholder="Search..."
            role="searchbox"
            tabIndex="0"
            type="search"
            value={query}
        />
    </form>
)

export default SearchForm