import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Layout, PostCard } from '../components/common'
import { MetaData } from '../components/common/meta'
import SearchForm from "../components/common/SearchForm"
import SearchResultsList from "../components/common/SearchResultsList"

const Index = ({ data, location }) => {
    const posts = data.allGhostPost.edges
    const postValues = Object.values(posts)
    const allNodes = []
    const allTagsArray = []
    const allTags = []
    const uniqueTags = []
    const taglist = []

    const allPostNodes = []
    const allPosts = []

    const allFeaturedPostNodes = []
    const allFeaturedPosts = []

    const featuredItemLimit = 3
    const blogPostItemLimit = 1000

    // get all the nodes
    for (const node of postValues) {
        allNodes.push(node)
        allPostNodes.push(node)
        allFeaturedPostNodes.push(node)
    }

    // get all the tag object arrays into a single object array
    for (var i = 0, leni = allNodes.length; i < leni; i++) {
        for (const tagobj of allNodes[i].node.tags) {
            allTagsArray.push(tagobj)
        }
    }

    // get a specific number of Featured posts
    var featuredPostCount = 0
    for (var k = 0, lenk = allFeaturedPostNodes.length; k < lenk; k++) {
        if (allFeaturedPostNodes[k].node.featured === true && featuredPostCount < featuredItemLimit) {
            allFeaturedPosts.push({ node: allFeaturedPostNodes[k].node })
            featuredPostCount = featuredPostCount + 1
        }
    }

    // get a specific number of NON-Featured posts
    var postCount = 0
    for (var j = 0, lenj = allPostNodes.length; j < lenj; j++) {
        if (allPostNodes[j].node.featured === false && postCount < blogPostItemLimit) {
            allPosts.push({ node: allPostNodes[j].node })
            postCount = postCount + 1
        }
    }

    class PostTags extends React.Component {
        renderPostTags() {
            allTagsArray.forEach((e) => {
                if (uniqueTags.indexOf(e.slug) === -1) {

                    // get unique objects only
                    if (e.name.indexOf(`Blog.`) > -1) {
                        allTags.push({
                            key: e.slug,
                            count: 1,
                            parent: e.name.replace(`Blog.`,``).split(`.`)[0],
                            child1: e.name.replace(`Blog.`,``).split(`.`)[1],
                            child2: e.name.replace(`Blog.`,``).split(`.`)[2],
                            slug: e.slug,
                        })
                        uniqueTags.push(e.slug)
                    }
                } else {

                    // increment counts if not unique
                    for (var m = 0; m < allTags.length; m++) {
                        if (allTags[m].key === e.slug) {
                            allTags[m].count = allTags[m].count + 1
                        }
                    }
                }
            })

            allTags.sort((a, b) => ((a.name > b.name) ? 1 : -1))
            var allParents = allTags.sort((a, b) => ((a.count < b.count) ? 1 : -1))
            
            let u = []
            let ky = 0
            allParents.forEach((e) => {
                if (u.indexOf(e.parent) === -1) {

                    // push parent
                    taglist.push(
                        <h5 key={e.parent} title={e.parent}>{e.parent}</h5>
                    )

                    // push children
                    for (var n = 0; n < allTags.length; n++) {
                        if (allTags[n].parent === e.parent) {
                            taglist.push(
                              <span className="post-tag__link" key={allTags[n].child1 + allTags[n].count}>
                                <a href={`/tag/` + allTags[n].slug} 
                                    title={allTags[n].child1}>
                                    {allTags[n].child1}                      
                                </a>
                                <sup>{allTags[n].count}</sup>
                              </span>
                            )
                        }
                    }
                    ky = ky + 1
                    taglist.push(<br key={ky}/>)
                }

                u.push(e.parent)
            })
                        
            return taglist 
        }

        render() {
            return (
                <>
                    {this.renderPostTags(posts)}
                </>
            )
        }
	}

	// search result fields come from gatsby-config.js
    const [results, setResults] = useState([])
    const searchQuery = new URLSearchParams(location.search).get(`q`) || ``

    useEffect(() => {
        const searchQuery = new URLSearchParams(location.search).get(`q`) || ``      
        if (searchQuery && window.__LUNR__) {
            window.__LUNR__.__loaded.then((lunr) => {
                const refs = lunr.en.index.search(searchQuery.replace(/"/,``))
                const posts = refs.map(({ ref }) => lunr.en.store[ref])
                setResults(posts)
            })
        }
    }, [location.search])

    return (
        <>
            <MetaData location={location} />
			<Layout isHome={true}>
				
				{/* Searchbox */}
				<div className="container search">
					<SearchForm query={searchQuery}/>
					<SearchResultsList 
						query={searchQuery}
						results={results}
						location={[location.origin].toString()}
					/>
				</div>

				
                {/* Tags */}
                <div className="container">
                    {/* <h2>Tags</h2> */}
                    {posts && <section className="post-tag"><PostTags/></section>}
                </div>
                
                {/* Featured Posts */}
                <div className="container">
                    <h2>Featured Posts</h2>
                    <section className="tag-feed">
                        {allFeaturedPosts.map(({ node }) => (<PostCard key={node.id} post={node} />))}
                    </section>
                </div>

                {/* Posts */}
                <div className="container">
                    <h2>Recent Posts</h2>
                    <section className="tag-feed">
                        {/* Remove matching post from feed */}
                        {allPosts.map(({ node }) => {
                            if (!node.slug.startsWith('_')) {
                                return (<PostCard key={node.id} post={node} />)
                            } else {
                                return ''
                            }
                        })}
                    </section>
                </div>

                <div className="container"><br/></div>

            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
        allGhostTag: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// Return all posts except - for the Gatsby default "data-schema" card
export const pageQuery = graphql`
	query GhostPostQuery($limit: Int!, $skip: Int!) 
	{
		allGhostPost(
			filter: {
				slug: {
					ne: "data-schema"
				}
			}
			sort: {
				fields: [published_at]
				order: DESC
			}
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					...GhostPostFields
				}
			}
		}
		allGhostTag(
			filter: {
				slug: {
					ne: "data-schema"
				}
			}, 
			sort: { 
				fields: [postCount]
				order: DESC
			}
		) {
			edges {
				node {
					id
					slug
					name
					postCount
					visibility
				}
			}
		}
	}
`
